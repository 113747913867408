import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/assets/css/common/index.css'
import '../src/assets/css/element/index.css'
import '@/assets/css/iconfont/iconfont.css'
import './utils/rem'
import { isPhone, hidePhone } from '@/utils/utils'
Vue.prototype.$isPhone = isPhone
Vue.prototype.$hidePhone = hidePhone

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  store,

  render: h => h(App)
}).$mount('#app')
