// function intiSize() {
//     //获取当前浏览器窗口宽度(这里的实质就是body宽度)
//     var win_w = document.body.offsetWidth;
//     //定义变量
//     var fontSize;
//     // if (win_w > 1000) {
//     //     fontSize = 18;
//     // } else {
//     //     //如果浏览器窗口宽度(这里的实质就是body宽度)值小于320，取320
//     //     win_w = Math.max(320, win_w);
//     //     // fontSize = win_w / 320 * 12
//     //     fontSize = 20

//     // }
//     fontSize = 17;
//     //设置根元素的大小
//     document.getElementsByTagName('html')[0].style.fontSize = fontSize + 'px';
// }
// //浏览器窗口宽度发生变化时条用这个函数，方便与改变窗口大小时候调试
// onresize = intiSize;
// intiSize();




(function (window, document) {
    function resize() {
        var docEl = document.documentElement
        var clientWidth = docEl.clientWidth;
        if (!clientWidth) return;
        if (clientWidth >= 900) {
            docEl.style.fontSize = '24px';
        } else {
            docEl.style.fontSize = 30 * (clientWidth / 900) + 'px';
        }
    }
    if (document.readyState !== 'loading') {
        resize();
    } else {
        document.addEventListener('DOMContentLoaded', resize);
    }
    window.addEventListener('resize', resize);
})(window, document);