import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
    token: localStorage.getItem('token'),
    tags: localStorage.getItem('tags') || [],
  },
  getters: {
  },
  mutations: {
    removeInfo(state) {
      state.token = localStorage.removeItem('token')
      state.userInfo = localStorage.removeItem('userInfo')
      localStorage.removeItem('tags')
      state.tags = []
      state.userInfo = {}
    },
    setUserInfo(state, data) {
      localStorage.setItem('userInfo', JSON.stringify(data))
      state.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    },
  },
  actions: {
  },
  modules: {
  }
})
