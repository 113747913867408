
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    // redirect: {
    //   path: '/login',
    // }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home/index.vue'),
    redirect: {
      path: '/phoneList',
    },
    children: [
      {
        path: '/phoneList',
        name: 'phoneList',
        meta: {
          title: '查询号码'
        },
        components: { phoneList: () => import('../views/Phone/phoneList.vue') }
      },
      {
        path: '/phoneDetail/:id',
        name: 'phoneDetail',
        meta: {
          title: '客户详情'
        },
        components: { phoneDetail: () => import('../views/Phone/detail.vue') }
      },




    ]
  },

  {
    path: '/error/404',
    component: () => import('@/views/Error/404.vue'),
    meta: { hidden: true } // 使用meta字段隐藏侧边栏或导航菜单中的链接（如果适用）
  },

  {
    path: '/:pathMatch(.*)', // 使用:pathMatch(.*)作为通配符
    redirect: '/error/404', // 重定向到404页面
    meta: { hidden: true } // 同样，隐藏这个路由
  }


]
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token')
  if (to.path != '/login' && !token) {
    return next({ name: 'login' })
  }

  if (to.path == '/' && token) {
    return next({ name: 'home' })
  }
  next()
})

export default router
