export function isPhone() {
    let info = navigator.userAgent;
    return /mobile/i.test(info);
}


/**
 * @desc 手机号脱敏
 * @demo 155****8810
 */
export function hidePhone(phone) {
    return phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')

}


const tableHeightWind = (hei) => {
    let windowHeight = 0

    windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
    return windowHeight - hei
}
export function tableHeight(hei) {
    let windowHeight = 0
    window.addEventListener('resize', function (event) {
        windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        tableHeightWind(hei)
    });


    return {
        tableHeightWind
    }
}

